<template>
  <div>
    <CCol md="12">
      <CCard>
        <CCardHeader>
          <span><strong>Sözleşmeler</strong></span>
        </CCardHeader>
      </CCard>
      <CCard>
        <CCardBody>
          <CRow class="d-flex align-items-baseline mx-12">
            <CCol class="col-4">
              <v-switch v-model="subSiteSwitch" label="Alt Site"> </v-switch>
            </CCol>
            <CCol v-if="subSiteSwitch" class="col-md-3 col-4">
              <v-autocomplete
                class="select"
                v-model="selectedSite"
                v-show="subSiteSwitch"
                :items="subSiteInfo"
                item-text="selectedSiteValue.name"
                item-value="selectedSiteValue"
                @change="getSubSiteContractForms()"></v-autocomplete>
            </CCol>
          </CRow>
          <CRow
            class="d-flex justify-content-between align-items-baseline mx-12">
            <CCol class="col-md-3 col-6">
              <CRow>
                <v-autocomplete
                  v-model="selectedContract"
                  :items="contracts"
                  item-text="tr"
                  item-value="val"
                  label="Sözleşme"
                  return-object>
                </v-autocomplete>
              </CRow>
            </CCol>

            <v-col cols="8">
              <span class="font-weight-bold pb-2 mb-2">
                {{ " Dil Seçimi :   " }}
              </span>
              <v-btn-toggle
                v-model="btnToggleLanguage"
                borderless
                mandatory
                style="width: 90%"
                active-class="red white--text">
                <v-btn
                  small
                  style="width: 50%; border-radius: 50px 0 0 50px"
                  class="ls-0 text-capitalize font-weight-bold">
                  {{ "ingilizce" }}
                </v-btn>
                <v-btn
                  small
                  style="width: 50%; border-radius: 0 50px 50px 0"
                  class="ls-0 text-capitalize font-weight-bold">
                  {{ "almanca" }}
                </v-btn>
              </v-btn-toggle></v-col
            >
          </CRow>

          <CRow>
            <CCol v-if="contractForm.title == 'distanceSellingContract'">
              <v-card class="mx-auto" color="red" dark max-width="100%">
                <v-card-text
                  class="text-p py-2 px-3"
                  v-pre
                  style="
                    font-weight: bold;
                    text-align: center;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                  ">
                  {{ KELIME }} Bu formatta yazılan ifadeler otomatik olarak
                  doldurulur.Lütfen metinde değişiklik yaparken bu ifadelerde
                  herhangi bir değişiklik olmamasına dikkat edelim.
                </v-card-text>
              </v-card>
            </CCol>
          </CRow>
          <CRow v-if="selectedSite.selected">
            <CCol sm="6">
              <span>İçerik (TR) {{ selectedSite.name }}</span>
              <ckeditor
                v-model="subSiteContractForm.content.tr"
                :config="editorConfigTr"
                class="mt-3"></ckeditor>
            </CCol>
            <CCol sm="6" v-if="!btnToggleLanguage">
              <span>İçerik (EN) {{ selectedSite.name }}</span>
              <ckeditor
                v-model="subSiteContractForm.content.en"
                :config="editorConfigEn"
                class="mt-3"></ckeditor>
            </CCol>
            <CCol sm="6" v-else>
              <span>İçerik (DE) {{ selectedSite.name }}</span>
              <ckeditor
                v-model="subSiteContractForm.content.de"
                :config="editorConfigDe"
                class="mt-3"></ckeditor>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol sm="6">
              <span>İçerik (TR)</span>
              <ckeditor
                v-model="contractForm.content.tr"
                :config="editorConfigTr"
                class="mt-3"></ckeditor>
            </CCol>
            <CCol sm="6" v-if="!btnToggleLanguage">
              <span>İçerik (EN)</span>
              <ckeditor
                v-model="contractForm.content.en"
                :config="editorConfigEn"
                class="mt-3"></ckeditor>
            </CCol>
            <CCol sm="6" v-else>
              <span>İçerik (DE)</span>
              <ckeditor
                v-model="contractForm.content.de"
                :config="editorConfigDe"
                class="mt-3"></ckeditor>
            </CCol>
          </CRow>
          <v-btn @click="addAndUpdateContract()" class="primary">
            Kaydet
          </v-btn>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
</template>

<script>
import Vue from "vue";
import ckeditor from "ckeditor4-vue"; // https://ckeditor.com/docs/ckeditor4/latest/guide/dev_vue.html#basic-usage
Vue.use(ckeditor);

import axios from "axios";
export default {
  name: "Contracts",
  data() {
    return {
      editorConfigTr: {
        height: "500px",
      },
      editorConfigEn: {
        height: "500px",
      },
      editorConfigDe: {
        height: "500px",
      },
      btnToggleLanguage: false,
      contracts: [
        {
          val: "commercialMessage",
          en: "Commercial Message",
          tr: "Ticari İleti",
        },
        {
          val: "cookiePolicy",
          en: "Cookie Policy",
          tr: "Çerez Politikası",
        },
        {
          val: "explicitConsentText",
          en: "Explicity Consent Text",
          tr: "Açık Rıza Metni",
        },
        {
          val: "kvkk",
          en: "KVKK",
          tr: "KVKK",
        },
        {
          val: "membershipAgreement",
          en: "Membership Agreement",
          tr: "Üyelik Sözleşmesi",
        },
        {
          val: "distanceSellingContract",
          en: "Distance Selling Contract",
          tr: "Mesafeli Satış Sözleşmesi",
        },
      ],

      selectedContract: null,
      subSiteSwitch: false,
      selectedSite: {
        id: "",
        name: "",
        selected: false,
      },
      subSiteInfo: [],
      selectedContractValue: "",
      contractForm: {
        _id: null,
        title: "",
        content: {
          tr: "",
          en: "",
          de: "",
        },
      },
      subSiteContractForm: {
        _id: null,
        subSiteId: null,
        title: "",
        content: {
          tr: "",
          en: "",
          de: "",
        },
      },
    };
  },
  watch: {
    selectedContract: function (val) {
      if (val) {
        this.contractForm.title = this.selectedContract.val;
        this.getContractForms(val);
      } else {
        this.cleanContractForms();
        this.cleanSubSiteForm();
      }
    },
    subSiteSwitch: function (val) {
      if (val) {
        this.subSiteInfos();
      } else {
        this.cleanSubSiteForm();
      }
    },
  },
  methods: {
    async subSiteInfos() {
      try {
        await axios
          .get(process.env.VUE_APP_API_URL + "admin/subsites")
          .then((response) => {
            this.subSiteInfo = response.data.subSites.map((item) => {
              return {
                ...item,
                selectedSiteValue: {
                  name: item.name,
                  id: item._id,
                },
              };
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    addAndUpdateContract() {
      let data;
      if (this.selectedSite.selected) {
        data = this.subSiteContractForm;
        data.subSiteId = this.selectedSite.id;
      } else {
        data = this.contractForm;
      }
      data.title = data.title ? data.title : this.selectedContract.val;
      axios
        .post(process.env.VUE_APP_API_URL + "admin/contract", data)
        .then((result) => this.successSwal("Sözleşme Güncellendi"))
        .catch((err) => {
          Vue.swal.fire({
            icon: "error",
          });
        });
    },
    getContractForms(val) {
      this.selectedSite.selected
        ? this.cleanSubSiteForm()
        : this.cleanContractForms();

      const subSiteId = this.selectedSite.id;

      let resp;
      axios
        .get(process.env.VUE_APP_API_URL + "admin/contract", {
          params: {
            val: val["val"],
            subSiteId,
          },
        })
        .then(async (response) => {
          if (response.data[0]) {
            resp = await response.data[0];
            this.contractForm = await resp;
          } else {
          }
        });
    },

    async getSubSiteContractForms(val) {
      this.selectedSite.selected = true;
      this.selectedContract = null;
    },
    cleanContractForms() {
      this.contractForm._id = null;
      this.contractForm.title = "";
      this.contractForm.content.tr = "";
      this.contractForm.content.en = "";
      this.contractForm.content.de = "";
    },
    cleanSubSiteForm() {
      this.selectedSite.selected = false;
      this.subSiteContractForm._id = null;
      this.subSiteContractForm.subSiteId = null;
      this.subSiteContractForm.title = "";
      this.subSiteContractForm.content.tr = "";
      this.subSiteContractForm.content.en = "";
      this.subSiteContractForm.content.de = "";
    },
    cleanSelectedSiteForm() {
      this.selectedSite.selected = false;
      this;
    },
    successSwal(text, refresh = true) {
      let timerInterval;
      Vue.swal
        .fire({
          icon: "success",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result.dismiss === Vue.swal.DismissReason.timer) {
            refresh ? location.reload() : "";
          }
        });
    },
  },
};
</script>

<style scoped>
.ck-editor__editable {
  min-height: 800px;
}
</style>
